<template>
  <div>
    <div class="alert alert-dismissible d-flex flex-column flex-sm-row w-100 p-5 mb-10" :class="alertClasses">
      <!--begin::Content-->
      <div class="d-flex flex-column text-light pe-0 pe-sm-10">
        <template v-if="content">
          <ul v-if="Array.isArray(content)" class="mb-0">
            <li v-for="(c, index) in content" :key="`c-${index}`">{{ c }}</li>
          </ul>
          <span v-else>{{ content }}</span>
        </template>
      </div>
      <!--end::Content-->
      <!--begin::Close-->
      <button
        type="button"
        class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
        data-bs-dismiss="alert"
        @click.prevent="$emit('dismiss-alert', $event)"
      >
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
        <span class="svg-icon svg-icon-2x svg-icon-light">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
          </svg>
        </span>
        <!--end::Svg Icon-->
      </button>
      <!--end::Close-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseAlert',
  props: ['content', 'type', 'message'],
  computed: {
    alertClasses () {
      const array = []
      switch (this.type) {
        case 'error' :
          array.push('bg-danger')
          break
        case 'success' :
          array.push('bg-success')
          break
        default:
          array.push('bg-info')
      }

      if (this.content && this.type === 'error') array.push('alert-error-list')

      return array
    }
  }
}
</script>

<style lang="scss" scoped>

.alert {
  font-size: $font_size_small;
}

</style>
